import React from "react";
import Section from "../components/Section";

import "./ThreeCol.scss";

export default function ThreeCol() {
	return (
		<Section className="three-col">
			<div className="three-col__container">
				<div className="three-col__intro">
					<h2>Intro</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
				</div>
				<div className="three-col__feature three-col__feature--1">
					<img src="http://placekitten.com/500/500" alt="" />
					<p>
						Duis convallis tellus ex, scelerisque porttitor orci mattis sed.
					</p>
				</div>
				<div className="three-col__feature three-col__feature--2">
					<img src="http://placekitten.com/500/500" alt="" />
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
				</div>
				<div className="three-col__feature three-col__feature--3">
					<img src="http://placekitten.com/500/500" alt="" />
					<p>Donec nec enim mi.</p>
				</div>
			</div>
		</Section>
	);
}
