import React from "react";
import Section from "../components/Section";

import "./FeatureImage.scss";

export default function FeatureImage() {
	return (
		<Section className="feature-image">
			<div className="feature-image__container">
				<div className="feature-image__image">
					<img
						src="http://placekitten.com/g/2400/1400"
						width="2400"
						height="1400"
						alt=""
					/>
				</div>
			</div>
		</Section>
	);
}
