import React from "react";
import Section from "../components/Section";

import "./LeftyRighty.scss";

export default function LeftyRighty() {
	return (
		<Section className="lefty-righty">
			<div className="lefty-righty__container">
				<div className="lefty-righty__panel">
					<div className="lefty-righty__panel__image">
						<img src="http://placekitten.com/1400/1000" alt="" />
					</div>
					<div className="lefty-righty__panel__copy">
						<h2>Heading</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
							convallis tellus ex, scelerisque porttitor orci mattis sed.
							Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
							nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
							scelerisque laoreet lobortis.
						</p>
					</div>
				</div>
				<div className="lefty-righty__panel">
					<div className="lefty-righty__panel__image">
						<img src="http://placekitten.com/1400/1000" alt="" />
					</div>
					<div className="lefty-righty__panel__copy">
						<h2>Heading</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
							convallis tellus ex, scelerisque porttitor orci mattis sed.
							Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
							nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
							scelerisque laoreet lobortis.
						</p>
					</div>
				</div>
			</div>
		</Section>
	);
}
