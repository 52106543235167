import React from "react";
import Section from "../components/Section";

import "./Illustrated.scss";

export default function Illustrated() {
	return (
		<Section className="illustrated">
			<div className="illustrated__container">
				<div className="illustrated__image">
					<img
						src="http://placekitten.com/2400/1600"
						width="2400"
						height="1600"
						alt=""
					/>
				</div>
				<div className="illustrated__copy">
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						convallis tellus ex, scelerisque porttitor orci mattis sed.
						Phasellus lobortis tristique dapibus. Ut nisl nisl, faucibus ac
						nulla pretium, posuere bibendum ex. Donec nec enim mi. Sed
						scelerisque laoreet lobortis.
					</p>
				</div>
				<div className="illustrated__image-small">
					<img
						src="http://placekitten.com/g/600/500"
						width="600"
						height="500"
						alt=""
					/>
				</div>
			</div>
		</Section>
	);
}
