import React from "react";
import Section from "../components/Section";

import "./Blockquote.scss";

export default function Blockquote() {
	return (
		<Section className="blockquote">
			<div className="blockquote__container">
				<div className="blockquote__copy">
					<blockquote>
						"Lorem ipsum dolor sit amet, congue vitae adipiscing."
					</blockquote>
				</div>
			</div>
		</Section>
	);
}
