import React from "react";

import "./Hero.scss";

export default function Hero({ children }) {
	return (
		<div className="hero">
			<div className="hero__container">
				<div className="hero__inner">
					<div className="hero__title">
						<h1>{children}</h1>
					</div>
				</div>
			</div>
		</div>
	);
}
